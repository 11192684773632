<template>
  <div class="d-flex h-100 justify-center align-center">
    <div class="workspace">
      <XyzTransition
        appear
        xyz="fade left-3 delay-3"
      >
        <v-card
          class="pa-8"
          max-width="720px"
        >
          <div class="d-flex flex-column justify-space-between">
            <XyzTransition
              appear
              xyz="fade left-3 delay-3"
            >
              <v-img
                :src="require('@/assets/images/svg/oriens-text.svg')"
                width="120px"
                alt="logo"
                contain
                eager
                class="app-logo mt-2"
              />
            </XyzTransition>
            <v-card-text class="my-8">
              <p class="text-2xl font-weight-semibold text--primary mb-2">
                Harap Cek Email Untuk verifikasi akun anda 📧
              </p>
              <p class="mb-8">
                Sebelum anda bisa melakukan login, anda harus melakukan verifikasi akun anda terlebih dahulu.
              </p>
            </v-card-text>
            <router-link to="/login">
              <v-btn
                color="primary"
                block
              >
                Ke Halaman Login
              </v-btn>
            </router-link>
          </div>
        </v-card>
      </XyzTransition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { emailValidator } from '@core/utils/validation'
import store from '@/store'
import router from '@/router'
import breath from '@/assets/lottie/breath.json'

export default {
  setup() {
    const isPasswordVisible = ref(false)

    const loginForm = ref(null)
    const email = ref('')
    const password = ref('')
    const loading = computed(() => store.state.auth.loadingLogin)

    const login = () => {
      if (email.value && password.value) {
        store
          .dispatch('login', {
            email: email.value,
            password: password.value,
          })
          .then(() => {
            Vue.notify({
              title: 'Berhasil login!',
              type: 'success',
              text: 'Selamat datang di OriensCRM.',
            })

            const savedUrl = localStorage.getItem('savedUrl')

            if (savedUrl) {
              localStorage.removeItem('savedUrl')

              router.replace(savedUrl).catch(() => {})
            } else {
              router.replace('/').catch(() => {})
            }
          })
      }
    }

    return {
      isPasswordVisible,
      loginForm,
      email,
      password,
      loading,
      login,
      breath,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      emailValidator,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
